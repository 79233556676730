import React from 'react'
import { graphql } from 'gatsby'
import ProjectHeader from '../../components/Header/ProjectHeader'
import {SimpleGrid} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import MobileVid from '../../video/nfl-mobile.mp4'
import DesktopVid from '../../video/nfl-desktop.mp4'
import PageVid from '../../video/nfl-page-sweep.mp4'
import LightsVid from '../../video/lights.mp4'
import {mypx, xgap } from "../../constants/spacing";
import SilentPlayer from '../../components/video/SilentPlayer'
import SEO from '../../components/SEO'
import ProjectArrows, { ProjectLinkers } from '../../components/Header/ProjectArrows'


const preventClick = (e) => e.preventDefault()
const nfl = ( { data, location } ) => {
  const isSSR = typeof window === 'undefined'
  const currentPath = location.pathname ? location.pathname : ''
    // const xgap = [20,null,null,null,7]
    // const mypx = ["10px", null, null, null, "25px"];
    const dat = data.wpProject.hollandProjects
    const img1 = getImage(dat.image1.localFile);
    const img2 = getImage(dat.image2.localFile);
    const img3 = getImage(dat.image3.localFile);
    const img4 = getImage(dat.image4.localFile);
    const img5 = getImage(dat.image5.localFile);
    const img6 = getImage(dat.image6.localFile);
    const img7 = getImage(dat.image7.localFile);
    return (
        <>
        <SEO title={dat.projecttitle} description={dat.projectdescription} image={img1} url="https://hollandhames.com/project/nfl"/>
        <ProjectHeader mobileVid={MobileVid} desktopVid={DesktopVid} title1="NFL HOF" title2="JERRY JONES" description={dat.projectdescription} />
        <SimpleGrid pb={xgap} columns={1} px={mypx} gap={xgap}>
            <SimpleGrid gap={xgap} columns={[1,null,null,null,2]}>
                <GatsbyImage onContextMenu={preventClick} image={img1} />
                <GatsbyImage onContextMenu={preventClick} image={img2} />
            </SimpleGrid>
            <SimpleGrid columns={1} gap={xgap}>
                <GatsbyImage onContextMenu={preventClick} image={img3} />
            </SimpleGrid>
            <SimpleGrid  gap={xgap} columns={[1,null,null,null,2]}>
                <GatsbyImage onContextMenu={preventClick} image={img4} />
                <SilentPlayer url={PageVid} />
            </SimpleGrid>
            <SimpleGrid columns={1} gap={xgap}>
                <GatsbyImage onContextMenu={preventClick} image={img5} />
            </SimpleGrid>
            <SimpleGrid columns={[1,null,null,null,2]} gap={xgap}>
                <GatsbyImage onContextMenu={preventClick} image={img6} />
                <GatsbyImage onContextMenu={preventClick} image={img7} />
            </SimpleGrid>
            <SimpleGrid columns={1} gap={xgap}>
            <SilentPlayer url={LightsVid} />
            </SimpleGrid>
        </SimpleGrid>


         <ProjectLinkers prevTitle='Hellofresh Marketing' prevUrl='/project/hellofresh-marketing' nextTitle='The Copper Club' nextUrl='/project/copper-club' />
 
      
        </>
    )
}

export default nfl

export const nflquery = graphql`
  {
    wpProject(title: { eq: "NFL Hall of Fame" }) {
      hollandProjects {
        projecttitle
        projectdescription
        projectcategories
        image1 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image1 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image2 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image3 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image4 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image5 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image6 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image7 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image8 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image9 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image10 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image11 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image12 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image13 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image14 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image15 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image16 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image17 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image18 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`;

