import React from 'react'
import ReactPlayer from 'react-player'
import {Box, Heading, Text, Container, Stack, SimpleGrid} from '@chakra-ui/react'
import { xgap, mypx } from '../../constants/spacing'
import './MyHead.css'
const ProjectHeader = ({
    desktopVid,
    mobileVid,
    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    title,
    title1,
    title2,
    title3,
    title4,
    h="100w",
    w="100vw"

    
}) => {
  const preventClick = (e) => e.preventDefault()

  if(title) {
  title1 = title.split(" ").slice(0, 1).join(" ");
  
  title2 = title.split(" ").slice(1, 2).join(" ");
  title3 = title.split(" ").slice(2, 3).join(" ");
  title4 = title.split(" ").slice(3, 4).join(" ");
  }
    return (
        <>
         <Box onContextMenu={preventClick} h={h} w={w} overflow='hidden'  display={["none", null, "block"]}>
        <ReactPlayer
          url={desktopVid}
          autoPlay={true}
          playsinline={true}
          autoplay={true}
          muted={true}
          loop={true}
          playing={true}
          width='100vw'
          height='100w'
          controls={false}
        />
      </Box>

      <Box display={["block", null, "none"]}>
        <ReactPlayer
          url={mobileVid}
          autoPlay={true}
          playsinline={true}
          autoplay={true}
          muted={true}
          loop={true}
          playing={true}
          width='100vw'
          height='100w'
          controls={false}
        />
      </Box>
      <SimpleGrid px={mypx} py={mypx}  columns={[1,null,null,null,2]}  pt={[10,null,null,null,36]}pb={[20,null,null,null,36]}>
        <Container>
      <Heading
          lineHeight='95%'
          pb={[8,null,null,null,0]}
          textAlign="left"
          textTransform="uppercase"
          fontSize={["3xl", "4xl", "5xl", "6xl", "7xl"]}>
          {title1} {title2 ? <br/> : null} {title2} {title3 ? <br /> : null} {title3} {title4 ? <br /> : null} {title4} 
        </Heading>
        </Container>
        <Container>
          <Text
            p='0'
            m='0'
            fontFamily='acumin-pro-semi-condensed'
            fontWeight='500'
            textAlign='justify'
            lineHeight='175%'
            
            fontSize={["lg"]}>
            {description}
          </Text>
        </Container>
      </SimpleGrid>
      {/* <Stack
        m='0 auto' py={[20,null,null,null,20]}
        justify={['center',null,null,null,'space-around']}
        spacing={['25px',null,null,null,0]}
        
        align={["center", null, null, null, "start"]}
        direction={["column", null, null, null, "row"]}>
        <Heading
          lineHeight='95%'
          p='0'
          textAlign="left"
          textTransform="uppercase"
          fontSize={["3xl", "4xl", "5xl", "6xl", "7xl"]}>
          {title1} {title2 ? <br/> : null} {title2} {title3 ? <br /> : null} {title3} {title4 ? <br /> : null} {title4} 
        </Heading>
        <Container>
          <Text
            p='0'
            m='0'
            fontFamily='acumin-pro-semi-condensed'
            fontWeight='500'
            textAlign='justify'
            lineHeight='175%'
            
            fontSize={["lg"]}>
            {description}
          </Text>
        </Container>
      </Stack> */}
        </>
    )
}

export default ProjectHeader
